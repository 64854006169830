// Order for MODEL_TYPE is important for ModelTypeSelect.
export enum MODEL_TYPE {
  ACTGAN = 'actgan',
  NAVIGATOR_FT = 'navigator_ft',
  GPT_X = 'gpt_x',
  TABULAR_DP = 'tabular_dp',
  NAVIGATOR = 'navigator',
  TRANSFORM_V2 = 'transform_v2',
}

// Model types that have deprecated.
export enum LEGACY_MODEL_TYPE {
  CTGAN = 'ctgan', // deprecated for 'actgan'
  TABLLM = 'tabllm', // renamed 'navigator'
  TRANSFORMS = 'transforms', // aliased to 'transform' in the backend
  DGAN = 'timeseries_dgan',
  SYNTHETICS = 'synthetics',
  AMPLIFY = 'amplify',
  TRANSFORM = 'transform',
  CLASSIFY = 'classify',
  EVALUATE = 'evaluate',
}

/* 
TODO this is a temporary solution to disable the deprecated models.

This LEGACY_MODEL_TYPE does not appear to the source of truth, but
instead some combination of the `modelTypesList.json` endpoint and 
knowledge that DGAN is cool too.  
*/
export const isDisabledModelType = (modelType: string) => {
  return [
    LEGACY_MODEL_TYPE.SYNTHETICS,
    LEGACY_MODEL_TYPE.AMPLIFY,
    LEGACY_MODEL_TYPE.TRANSFORM,
    LEGACY_MODEL_TYPE.TRANSFORMS,
    LEGACY_MODEL_TYPE.TRANSFORMS,
  ].includes(modelType as LEGACY_MODEL_TYPE);
};

export default MODEL_TYPE;

interface ModelAttributes {
  /**
   * enum mapping
   */
  modelType: MODEL_TYPE | LEGACY_MODEL_TYPE;
  /**
   * human-friendly label for model type
   */
  label: string;
  /**
   * color info for displaying model type icon
   */
  palette: {
    hue: 'primary' | 'info' | 'warning' | 'success' | 'grey' | 'error';
    shade: number;
  };
  /**
   * whether or not model should have a dropdown menu with options in the Details page.
   */
  hasPrimaryAction: boolean;
  /**
   * whether to expect runs of the model to have a report we can show.
   */
  hasReport: boolean;
  /**
   * Indicates whether or not model type can be used in the `gretel_tabular` workflow action.
   * When false, we must use the `gretel_model` workflow action instead.
   */
  hasTabularActionSupport: boolean;
  /**
   * When building a tabular workflow action, a `true` value for this field
   * tells us that we should include the `{ run: { num_records_multiplier: 1 } }`
   * configuration.
   */
  includeTabularNumRecordsMultiplier: boolean;
  /**
   * Tells us to include run params when building `gretel_model` actions
   * for this model type. Note that this field doesn't tell us what the value
   * of the run params should be (some require interpolation of other data).
   * You'll need to update the `getRunParamsForModelType` function in src/Console/Workflows/utils.tsx to specify that.
   */
  withWorkflowModelActionRunParams: boolean;
}

const Synthetic: ModelAttributes = {
  modelType: LEGACY_MODEL_TYPE.SYNTHETICS,
  label: 'Synthetic LSTM',
  palette: { hue: 'primary', shade: 500 },
  hasPrimaryAction: true,
  hasReport: true,
  hasTabularActionSupport: true,
  includeTabularNumRecordsMultiplier: true,
  withWorkflowModelActionRunParams: true,
};
const Transform: ModelAttributes = {
  modelType: LEGACY_MODEL_TYPE.TRANSFORM,
  label: 'Transform',
  palette: { hue: 'info', shade: 800 },
  hasPrimaryAction: true,
  hasReport: true,
  hasTabularActionSupport: true,
  includeTabularNumRecordsMultiplier: true,
  withWorkflowModelActionRunParams: true,
};
const TransformV2: ModelAttributes = {
  modelType: MODEL_TYPE.TRANSFORM_V2,
  label: 'Transform V2',
  palette: { hue: 'warning', shade: 700 },
  hasPrimaryAction: true,
  hasReport: true,
  hasTabularActionSupport: true,
  includeTabularNumRecordsMultiplier: false,
  withWorkflowModelActionRunParams: false,
};
const Classify: ModelAttributes = {
  modelType: LEGACY_MODEL_TYPE.CLASSIFY,
  label: 'Classify',
  palette: { hue: 'info', shade: 600 },
  hasPrimaryAction: true,
  hasReport: false,
  hasTabularActionSupport: false,
  includeTabularNumRecordsMultiplier: true,
  withWorkflowModelActionRunParams: true,
};
const Ctgan: ModelAttributes = {
  modelType: LEGACY_MODEL_TYPE.CTGAN,
  label: 'CTGAN',
  palette: { hue: 'primary', shade: 800 },
  hasPrimaryAction: true,
  hasReport: true,
  hasTabularActionSupport: false,
  includeTabularNumRecordsMultiplier: false,
  withWorkflowModelActionRunParams: false,
};
const Actgan: ModelAttributes = {
  modelType: MODEL_TYPE.ACTGAN,
  label: 'Synthetic ACTGAN',
  palette: { hue: 'primary', shade: 800 },
  hasPrimaryAction: true,
  hasReport: true,
  hasTabularActionSupport: true,
  includeTabularNumRecordsMultiplier: true,
  withWorkflowModelActionRunParams: true,
};
const Amplify: ModelAttributes = {
  modelType: LEGACY_MODEL_TYPE.AMPLIFY,
  label: 'Amplify',
  palette: { hue: 'warning', shade: 600 },
  hasPrimaryAction: true,
  hasReport: true,
  hasTabularActionSupport: true,
  includeTabularNumRecordsMultiplier: true,
  withWorkflowModelActionRunParams: true,
};
const Gpt: ModelAttributes = {
  modelType: MODEL_TYPE.GPT_X,
  label: 'GPT',
  palette: { hue: 'success', shade: 500 },
  hasPrimaryAction: true,
  hasReport: true,
  hasTabularActionSupport: false,
  includeTabularNumRecordsMultiplier: false,
  withWorkflowModelActionRunParams: true,
};
const Evaluate: ModelAttributes = {
  modelType: LEGACY_MODEL_TYPE.EVALUATE,
  label: 'Evaluate',
  palette: { hue: 'grey', shade: 700 },
  hasPrimaryAction: false,
  hasReport: false,
  hasTabularActionSupport: false,
  includeTabularNumRecordsMultiplier: false,
  withWorkflowModelActionRunParams: false,
};
const Dgan: ModelAttributes = {
  modelType: LEGACY_MODEL_TYPE.DGAN,
  label: 'DGAN',
  palette: { hue: 'error', shade: 500 },
  hasPrimaryAction: true,
  hasReport: false,
  hasTabularActionSupport: false,
  includeTabularNumRecordsMultiplier: false,
  withWorkflowModelActionRunParams: true,
};
const TabularDP: ModelAttributes = {
  modelType: MODEL_TYPE.TABULAR_DP,
  label: 'Tabular DP',
  palette: { hue: 'warning', shade: 600 },
  hasPrimaryAction: true,
  hasReport: true,
  hasTabularActionSupport: true,
  includeTabularNumRecordsMultiplier: true,
  withWorkflowModelActionRunParams: true,
};

const Navigator: ModelAttributes = {
  modelType: MODEL_TYPE.NAVIGATOR,
  label: 'Navigator',
  palette: { hue: 'grey', shade: 900 },
  hasPrimaryAction: false,
  hasReport: false,
  hasTabularActionSupport: true,
  includeTabularNumRecordsMultiplier: true,
  withWorkflowModelActionRunParams: true,
};

const NavigatorFT: ModelAttributes = {
  modelType: MODEL_TYPE.NAVIGATOR_FT,
  label: 'Navigator Fine Tuning',
  palette: { hue: 'primary', shade: 700 },
  hasPrimaryAction: true,
  hasReport: true,
  hasTabularActionSupport: true,
  includeTabularNumRecordsMultiplier: true,
  withWorkflowModelActionRunParams: true,
};

export const MODELS_BY_TYPE: Record<
  MODEL_TYPE | LEGACY_MODEL_TYPE,
  ModelAttributes
> = {
  [LEGACY_MODEL_TYPE.SYNTHETICS]: Synthetic,
  [LEGACY_MODEL_TYPE.TRANSFORM]: Transform,
  [LEGACY_MODEL_TYPE.TRANSFORMS]: Transform,
  [MODEL_TYPE.TRANSFORM_V2]: TransformV2,
  [LEGACY_MODEL_TYPE.CLASSIFY]: Classify,
  [LEGACY_MODEL_TYPE.CTGAN]: Ctgan,
  [MODEL_TYPE.ACTGAN]: Actgan,
  [LEGACY_MODEL_TYPE.AMPLIFY]: Amplify,
  [MODEL_TYPE.GPT_X]: Gpt,
  [LEGACY_MODEL_TYPE.EVALUATE]: Evaluate,
  [LEGACY_MODEL_TYPE.DGAN]: Dgan,
  [MODEL_TYPE.TABULAR_DP]: TabularDP,
  [MODEL_TYPE.NAVIGATOR]: Navigator,
  [LEGACY_MODEL_TYPE.TABLLM]: Navigator,
  [MODEL_TYPE.NAVIGATOR_FT]: NavigatorFT,
};

export type InferenceModelType = 'TABULAR' | 'NATURAL';

export const INFERENCE_MODELS_BY_TYPE: Record<InferenceModelType, string> = {
  TABULAR: 'Inference/Navigator',
  NATURAL: 'Inference/Natural language',
};

export const getLabelForModelType = (
  modelType: MODEL_TYPE | LEGACY_MODEL_TYPE | InferenceModelType
): string => {
  return (
    MODELS_BY_TYPE[modelType]?.label ||
    INFERENCE_MODELS_BY_TYPE[modelType] ||
    modelType
  );
};

export const isSyntheticCategoryModelType = (
  modelType: MODEL_TYPE | LEGACY_MODEL_TYPE
) => {
  return [
    LEGACY_MODEL_TYPE.CTGAN,
    LEGACY_MODEL_TYPE.TABLLM,
    MODEL_TYPE.ACTGAN,
    LEGACY_MODEL_TYPE.DGAN,
    LEGACY_MODEL_TYPE.AMPLIFY,
    MODEL_TYPE.GPT_X,
    LEGACY_MODEL_TYPE.SYNTHETICS,
    MODEL_TYPE.TABULAR_DP,
    MODEL_TYPE.NAVIGATOR,
    MODEL_TYPE.NAVIGATOR_FT,
  ].includes(modelType);
};

export const reverseMapModelTypes = (modelType: string | undefined) => {
  switch (modelType) {
    case 'synthetics':
      return LEGACY_MODEL_TYPE.SYNTHETICS;
    case 'classify':
      return LEGACY_MODEL_TYPE.CLASSIFY;
    case 'transform':
    case 'transforms':
      return LEGACY_MODEL_TYPE.TRANSFORM;
    case 'transform_v2':
      return MODEL_TYPE.TRANSFORM_V2;
    case 'gpt_x':
      return MODEL_TYPE.GPT_X;
    case 'actgan':
      return MODEL_TYPE.ACTGAN;
    case 'amplify':
      return LEGACY_MODEL_TYPE.AMPLIFY;
    case 'evaluate':
      return LEGACY_MODEL_TYPE.EVALUATE;
    case 'tabular_dp':
      return MODEL_TYPE.TABULAR_DP;
    case 'timeseries_dgan':
      return LEGACY_MODEL_TYPE.DGAN;
    case 'navigator':
      return MODEL_TYPE.NAVIGATOR;
    case 'navigator_ft':
      return MODEL_TYPE.NAVIGATOR_FT;
    default:
      return LEGACY_MODEL_TYPE.SYNTHETICS;
  }
};
